/* Provide sufficient contrast against white background */
.leaflet-container {
    width: 100%;
    height: 100%;
}

a {
    color: #0366d6;
}

code {
    color: #E01A76;
}

.btn-primary {
    color: #fff;
    background-color: rgb(19, 51, 45);
    border-color: #1861ac;
}

.volunteer-group-dropdown-colourbox {
    width: 12px;
    height: 12px;
    display: inline-block;
    border: 1px solid black;
    padding: 0px;
    margin-right: 5px;
}

.CardHeadLink {
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    padding-top: 2px;
    padding-bottom: 5px;
}

    .CardHeadLink:link {
        color: #ffffff;
    }

    .CardHeadLink:visited {
        color: #ffffff;
    }

.CardBodyLink {
}

    .CardBodyLink:link {
        color: rgb(19, 51, 45);
    }

    .CardBodyLink:visited {
        color: rgb(19, 51, 45);
    }

h4.card-title {
    margin: 0 !important;
    /*vertical-align: middle;*/
}

.card-header {
    background-color: rgb(19, 51, 45);
    border-color: rgb(19, 51, 45);
    color: #ffffff;
}

.card {
    border-color: rgb(19, 51, 45);
    min-width: 220px;
    margin-bottom: 10px !important;
}

.card-link {
    font-weight: bold;
}

    .card-link:link {
    }

    .card-link:visited {
    }

    .card-link:hover {
    }

.navbar-expand-sm {
    /*background-image: url(/images/header-large.jpg);
    background-repeat:no-repeat;
    background-size:cover;
        */
}

.navbar-collapse {
    background-color: rgb(19, 51, 45);
}

.nav {
    background-color: rgb(19, 51, 45);
    color: #ffffff !important;
}

.bg-dark {
    background-color: rgb(19, 51, 45) !important;
}

/*header {
    width:100%;
    margin-bottom: 10px;
}
*/
.navbar {
    background-color: rgb(19, 51, 45);
}

.nav-link {
    vertical-align: middle;
}
.nav-link-icon{
    margin-bottom:4px;
}
.dropdown-toggle {
    color: #ffffff;
}

.dropdown-menu {
    background-color: rgb(19, 51, 45);
}
.dropdown-item {
    background-color: rgb(19, 51, 45);
}
    .dropdown-item:hover {
        background-color: #4d89bd;
    }

.modal-header {
    background-color: rgb(19, 51, 45);
    color: #ffffff;
}


.modal-body .row:nth-child(even) {
    background-color: #f8f9fa;
}
.modal-body .row:nth-child(odd) {
    background-color: #ffffff;
}



.rbc-time-view .rbc-time-content {
    display: none;
}

